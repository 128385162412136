import { useEffect } from 'react';


/**
 * Hook that runs a function only on the client side.
 */
export const useOnClientOnly = <F extends () => void>(
  func: F,
  dependencies?: unknown[],
) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      func();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies ?? []);
};
